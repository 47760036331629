.loading-page {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(2, 1, 0); /* Soft orange background */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 9999;
  }
  
  .loading-page img {
    width: 200px;  /* Adjust the size as needed */
    height: auto;
    margin-bottom: 20px;  /* Space between the image and the slogan */
  }
  
  .loading-page h1 {
    font-size: 24px;  /* Adjust font size as needed */
    color: white;  /* Ensure the text is white */
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
  }
  /* ...existing CSS... */

.fade-in {
    animation: fadeIn ease 2s;
  }
  
  @keyframes fadeIn {
    0% {opacity: 0;}
    100% {opacity: 1;}
  }
  