/* Existing Styles */
.navbar {
  background-color: rgba(0, 0, 0, 0.834);
  height: 70px;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 0 2rem;
}

.navbar-left,
.navbar-right {
  flex: 1;
}

.navbar-center {
  flex: 2;
  text-align: center;
}

.navbar-logo {
  max-height: 65px;
}

.navbar-list {
  display: flex;
  justify-content: center;
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin-left: 120px;
}

.navbar-item {
  padding: 0 1rem;
}

.navbar-link {
  color: #ffffff;
  font-weight: 600;
  font-family: 'Raleway', sans-serif;
  text-decoration: none;
}

.menu-icon {
  display: none;
  color: #ffffff;
}

/* Language Toggle Switch */
.language-toggle {
  position: relative;
  display: inline-block;
  width: 40px;  /* Reduced from 60px */
  height: 22px;  /* Reduced from 34px */
  margin-left: 300px;
  margin-bottom: 15px;
}

.language-toggle input {
  opacity: 0;
  width: 0;
  height: 0;
}

.language-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 22px;  /* Adjusted to match new height */
}

.language-slider:before {
  position: absolute;
  content: "";
  height: 18px;  /* Reduced from 26px */
  width: 18px;  /* Reduced from 26px */
  left: 2px;  /* Reduced from 4px */
  bottom: 2px;  /* Reduced from 4px */
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

.language-toggle input:checked + .language-slider {
  background-color: #2196F3;
}

.language-toggle input:focus + .language-slider {
  box-shadow: 0 0 1px #2196F3;
}

.language-toggle input:checked + .language-slider:before {
  transform: translateX(18px);  /* Reduced from 26px */
}

/* Language label */
.language-label {
  margin-left: 310px;
  color: #ffffff;
  font-size: 1rem;
  margin-top: 5px;
  font-weight: bold;
  font-family: 'Raleway', sans-serif;  /* Changed from 'font' to 'font-family' */
}

/* Media Queries */
/* Media Queries */
@media (max-width: 768px) {
  .navbar {
    height: auto;
  }

  .navbar-container {
    flex-direction: row;
    align-items: center;
  }

  .navbar-left,
  .navbar-right,
  .navbar-center {
    width: auto;  /* No forced full width */
  }

  .navbar-logo {
    max-height: auto;
  }

  .navbar-list {
    white-space: nowrap;  /* Prevents wrapping */
    overflow-x: auto;  /* Adds horizontal scroll */
    -webkit-overflow-scrolling: touch;  /* Smooth scrolling for webkit */
  }

  .navbar-item {
    padding: 0 0.5rem; 
  }
  
  .language-toggle,
  .language-label {
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
}
/* Media Queries for landscape */
@media screen and (orientation:landscape) and (max-width: 768px) {
  .navbar-list {
    overflow-x: scroll;
    max-height: 50px;
    white-space: nowrap;
  }
  .navbar-item {
    padding: 0 0.3rem;
  }
}