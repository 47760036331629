.epic-moments {
  background: no-repeat center center fixed;
  background-image: url('/Users/idoivry/Desktop/website/itss.life/public/palm_trees.jpg');
  background-size: cover;
  color: #ffffff;
  padding: 15px;
  text-align: center;
  font-size: 18px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-size: 100%;
}

.epic-image {
  filter: grayscale(100%);  /* Convert image to black and white */
  width: 200px;
  height: auto;
  margin: 10px;
}

/* Media Query for Mobile Responsiveness */
@media (max-width: 768px) {
  .epic-moments {
    /* Reduce padding */
    padding: 10px;
    /* Scale down font size */
    font-size: 16px;
    /* Adjust background if needed; You might also want to consider using a mobile-specific image */
    background-size: cover;
    background-position: center;
  }
  
  .epic-image {
    /* Reduce image dimensions and margin */
    width: 150px;
    margin: 5px;
  }
}
