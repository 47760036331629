body {
  background-color: #f8f9fa;  /* Light gray background */
  font-family: 'Arial', sans-serif;
  color: #343a40;  /* Dark gray text */
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
