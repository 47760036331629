.floating-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
}

.main-button {
  width: 50px;
  height: 50px;
  background-color: #007bff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  color: white;
}

.main-button.open {
  background-color: #343a40;
  transform: rotate(90deg);
  color: white;
}

.main-button:hover {
  box-shadow: 0 0 15px rgba(0,0,0,0.3);
}

.social-icons {
  position: absolute;
  bottom: 70px;
  right: 0;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;
}

.icon {
  background-color: gray;
  color: white;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  font-size: 24px;
  line-height: 50px;
  text-align: center;
  margin-bottom: 10px;
  text-decoration: none;
  transition: all 0.3s ease;
}

.icon:hover {
  box-shadow: 0 0 15px rgba(0,0,0,0.3);
  transform: scale(1.1);
}

.facebook {
  background-color: #3b5998;
}

.linkedin {
  background-color: #0077b5;
}

.whatsapp {
  background-color: #25D366;
}

.tiktok {
  background-color: #000000;
}

.instagram {
  background-color: #E1306C;
}

/* Existing Styles ... */

/* 
@media (max-width: 768px) {
  .floating-button {
    bottom: 5px;  
    right: 5px;  
  }
  
  .main-button,
  .icon {
    width: 30px;  
    height: 30px;  
    line-height: 30px; 
  }

  .social-icons {
    bottom: 40px;  
  }
}
 */