.courses-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  background-color: #959393;
  border-radius: 10px;
}

.courses-title {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #000000;
}

.courses-list {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.course-item {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 10px;
}

.course-item h2 {
  font-size: 1.5rem;
  color: #fff;
  margin-bottom: 15px;
}

.course-item p {
  color: #fff;
  text-align: center;
}

.private-lesson {
  background: linear-gradient(to bottom right, #ff7e5f, #feb47b);
}

.group-lesson {
  background: linear-gradient(to bottom right, #376896, #133a5b);
}

.rtl {
  direction: rtl;
}

/* Media Query for Mobile Responsiveness */
@media (max-width: 768px) {
  .courses-container {
    padding: 20px;
  }

  .courses-title {
    font-size: 2rem;
    margin-bottom: 15px;
  }

  .courses-list {
    flex-direction: column;
    align-items: stretch;
  }

  .course-item {
    flex: 1;
    margin: 5px;
    padding: 15px;
  }

  .course-item h2 {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }
}
